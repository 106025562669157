label {
  font-weight: 400 !important;
  font-size: 15px !important;
}

.list-header-one {
  margin: 0 !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  color: '#333' !important;
}

.hg-img-circle {
  width: 80px;
  height: 80px;
}

.list-header-two {
  margin: 0 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  color: '#333' !important;
}

.list-subheader {
  font-size: 13px;
  margin: 0;
  font-weight: 400;
}
.farms-ml-2 {
  margin-left: 20;
}
