.btn-active {
  background: rgb(42, 183, 169, 0.8) !important;
  color: #fff !important;
  font-weight: 600;
}
.btn-rem {
  margin: 0.3rem !important;
}
.card-header {
  margin-bottom: 0 !important;
}
table tbody tr td {
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
/* table tbody tr td {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
} */
.farmDetail_img-circle {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}
@media only screen and (max-width: 600px) {
  .mobile-100 {
    margin-top: 7px;
    height: 30vh;
  }
}
.input-with-text {
  display: flex;
  align-items: center;
}

/* .input-with-text input {
  margin-right: 10px;
} */