.Button {
  cursor: pointer;
  margin: 0 0 12px;
  background-color: #fff;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
  background-color: #00d1b2;
  border-color: transparent;
  color: #fff;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;
}

.CenterAlign {
  top: 25%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -25%);
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 5;
}

.Overlay.Show {
  display: block;
}

.Modal {
  position: fixed;
  bottom: -150vh;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  left: 0;
  padding: 0 15px 15px;
  transition: all 0.3s ease-out;
  z-index: 10;
  max-height: 60vh;
}

.Modal.Show {
  bottom: 0;
}

.Close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 10px;
  background-color: transparent;
  border: 0;
  font-size: 18px;
}

.Alert {
  position: fixed;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  top: 0;
  left: 0;
  color: #fff;
  background-image: linear-gradient(
    90deg,
    #ff9a8b 0%,
    #ff6a88 55%,
    #ff99ac 100%
  );
  margin: 0;
  width: 100%;
  text-align: center;
  z-index: 10;
}

.Alert:empty {
  padding: 0;
}