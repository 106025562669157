.Agriculture_btn-rem {
  margin: 0.3rem !important;
}
.Agriculture_card-header {
  margin-bottom: 0 !important;
}
.Agriculture_farmDetail_img-circle {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}
.card-calendar .fc-scrollgrid tbody tr td {
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
