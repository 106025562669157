.laporan-mingguan {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 16pt;
  margin-bottom: 0.3rem;
  letter-spacing: 3px;
}

.nama-kebun {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 24pt;
  font-weight: bold;
  color: rgb(146, 208, 80);
  margin-bottom: 0.3rem;
}
.logo-habibi {
  width: 200px;
  height: 40px;
}
.kotak {
  border-radius: 40px;
  background-color: rgb(153, 255, 153, 0.4);
}
.kotak-kendala {
  border-radius: 40px;
  background-color: rgb(55, 255, 55, 0.48);
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 14pt;
}
.kebun-produksi {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 14pt;
  color: rgb(106, 65, 44);
}
.icon-tanaman {
  width: 100px;
  height: 100px;
  margin-top: 0.5rem;
}
.nama-tanaman {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 16pt;
  color: rgb(146, 208, 80);
  font-weight: bold;
  margin-top: 0.5rem;
}
.hst {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 20pt;
  color: rgb(106, 65, 44);
  font-weight: bold;
  margin: 0;
}
.fase-jumlah-tanaman {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 14pt;
  color: rgb(106, 65, 44);
  font-weight: bold;
  margin: 0;
}
.tabel {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 14pt;
  width: 100%;
  text-align: center;
}
.th-hijau {
  color: white;
  background-color: rgb(112, 173, 71);
  border: 1px solid rgb(112, 173, 71);
}
.tr-hijau {
  border: 1px solid rgb(112, 173, 71);
}
.td-hijau {
  padding: 0;
}
.judul-tabel {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 14pt;
  color: rgb(106, 65, 44);
  font-weight: bold;
  margin: 0;
}

.th-hitam {
  color: black;
  background-color: rgb(165, 165, 165);
  border: 1px solid white;
}

.tr-hitam:nth-child(even) {
  background-color: rgb(240, 240, 240);
}
.tr-hitam:nth-child(odd) {
  background-color: rgb(225, 225, 225);
}
.td-hitam {
  padding: 0;
  border: 1px solid white;
}
.judul-kotak {
  font-weight: bold;
}

@media print {
  .halaman-baru {
    page-break-before: always;
  }
  .laporan-mingguan {
    font-size: 9pt;
  }
  .nama-kebun {
    font-size: 16pt;
  }
  .logo-habibi {
    width: 1.15in;
    height: 0.22in;
  }
  .kebun-produksi {
    font-size: 8pt;
  }
  .nama-tanaman {
    font-size: 9pt;
  }
  .fase-jumlah-tanaman {
    font-size: 7pt;
  }
  .tabel {
    font-size: 11pt;
  }
  .icon-tanaman {
    width: 0.57in;
    height: 0.75in;
    margin-top: 0.5rem;
  }
  .judul-tabel {
    font-size: 11pt;
  }
  .kotak-kendala {
    font-size: 10pt;
  }
  .gambar-laporan {
    max-height: 250px;
    width: 100%;
  }
}
